/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createChart, ColorType } from 'lightweight-charts';
import {
   Button,
   Card,
   Checkbox,
   Col,
   DatePicker,
   Empty,
   Form,
   Radio,
   Row,
   Segmented,
   Select,
} from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import moment from 'moment';
import InputNumberComponent from '../../components/InputNumberComponent';
import LoadingComponent from '../../components/LoadingComponent';
import { debounce } from 'lodash';
import useRandomInt from '../../hooks/useRandomInt';
import { defaultTemplate, TEMPLATES } from './Templates';
import { validateDateRange } from '../../utils/validateDateRange';
import { findClosestNumbers } from '../../utils/findClosestNumbers';

const { Option } = Select;
const { RangePicker } = DatePicker;

const initialValueTimestamp = {
   fourHour: [],
   oneHour: [],
   fifteenMinutes: [],
   fiveMinutes: [],
   oneMinute: [],
   tenSeconds: [],
};

const initialValueChart = {
   fourHourValue: [],
   oneHourValue: [],
   fifteenMinuteValue: [],
   fiveMinuteValue: [],
   oneMinuteValue: [],
};

const TemplateSelector = (props) => (
   <Select {...props} className="w-full" defaultValue={defaultTemplate}>
      <Option value="t1">Template 1</Option>
      <Option value="t2">Template 2</Option>
      <Option value="t3">Template 3</Option>
      <Option value="custom" disabled>
         Custom
      </Option>
   </Select>
);

const ChartGenerator2Component = () => {
   const [general_Form] = Form.useForm();

   const [trend_Form] = Form.useForm();

   const chartContainerRef = useRef();

   const rangePickerRef = useRef(null);

   const chartRef = useRef(null);

   const seriesRef = useRef(null);

   const [isLoading, setLoading] = useState(false);

   const [timestamps, setTimestamps] = useState(initialValueTimestamp);

   const [dataChart, setDataChart] = useState(initialValueChart);

   const [currentDataChart, setCurrentDataChart] = useState([]);

   const getRandomInt = useRandomInt();

   const [chartsController, setChartsController] = useState(null);

   const [showSnipe, setShowSnipe] = useState(false);

   const [typeChart, setTypeChart] = useState('line');

   const [groupCandle, setGroupCandle] = useState('5m');

   const changedFieldRef = useRef(null);

   const delay = (ms = 100) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
   };

   const createLoading = (ms = 3000) => {
      setLoading(true);
      setTimeout(() => {
         setLoading(false);
         if (rangePickerRef.current) {
            setTimeout(() => {
               rangePickerRef.current.blur();
            }, 1);
         }
      }, ms);
   };

   const resetAllState = () => {
      setLoading(false);
      setTimestamps(initialValueTimestamp);
      setDataChart(initialValueChart);
      trend_Form.resetFields();
   };

   const generateTimestamps = (dates) => {
      if (!dates) return;

      const timestamp1 = dates[0]?.valueOf();
      const timestamp2 = dates[1]?.valueOf();

      if (!timestamp1 || !timestamp2) return;

      const fourHoursInMs = 4 * 60 * 60 * 1000;
      const oneHourInMs = 1 * 60 * 60 * 1000;
      const fifteenMinutesInMs = 15 * 60 * 1000;
      const fiveMinutesInMs = 15 * 60 * 1000;
      const oneMinuteInMs = 1 * 60 * 1000;
      const tenSecondsInMs = 10 * 1000;

      const fourHour = [];
      const oneHour = [];
      const fifteenMinutes = [];
      const fiveMinutes = [];
      const oneMinute = [];
      const tenSeconds = [];

      let currentTimestamp = timestamp1;

      // 4h
      while (currentTimestamp <= timestamp2) {
         fourHour.push(currentTimestamp);
         currentTimestamp += fourHoursInMs;
      }
      if (currentTimestamp - fourHoursInMs < timestamp2) {
         fourHour.push(timestamp2);
      }

      // 1h
      currentTimestamp = timestamp1;
      while (currentTimestamp <= timestamp2) {
         oneHour.push(currentTimestamp);
         currentTimestamp += oneHourInMs;
      }
      if (currentTimestamp - oneHourInMs < timestamp2) {
         oneHour.push(timestamp2);
      }

      // 15m
      currentTimestamp = timestamp1;
      while (currentTimestamp <= timestamp2) {
         fifteenMinutes.push(currentTimestamp);
         currentTimestamp += fifteenMinutesInMs;
      }
      if (currentTimestamp - fifteenMinutesInMs < timestamp2) {
         fifteenMinutes.push(timestamp2);
      }

      // 5m
      currentTimestamp = timestamp1;
      while (currentTimestamp <= timestamp2) {
         fiveMinutes.push(currentTimestamp);
         currentTimestamp += fiveMinutesInMs;
      }
      if (currentTimestamp - fiveMinutesInMs < timestamp2) {
         fiveMinutes.push(timestamp2);
      }

      // 1m
      currentTimestamp = timestamp1;
      while (currentTimestamp <= timestamp2) {
         oneMinute.push(currentTimestamp);
         currentTimestamp += oneMinuteInMs;
      }
      if (currentTimestamp - oneMinuteInMs < timestamp2) {
         oneMinute.push(timestamp2);
      }

      // 10s
      currentTimestamp = timestamp1;
      while (currentTimestamp <= timestamp2) {
         tenSeconds.push(currentTimestamp);
         currentTimestamp += tenSecondsInMs;
      }
      if (currentTimestamp - tenSecondsInMs < timestamp2) {
         tenSeconds.push(timestamp2);
      }

      const result = {
         fourHour,
         oneHour,
         fifteenMinutes,
         fiveMinutes,
         oneMinute,
         tenSeconds,
      };

      setTimestamps(result);

      return result;
   };

   const generatePrice = (timestamps, pattern, startPrice, endPrice) => {
      const diffPrice = endPrice - startPrice;
      const results = [];

      for (let index = 1; index < timestamps.length; index++) {
         const diffTime = timestamps[index] - timestamps[index - 1];
         const stepTime = diffTime / pattern.length;

         for (let i = 0; i <= pattern.length; i++) {
            const time = timestamps[index - 1] + stepTime * i;
            const value = +(
               startPrice +
               diffPrice * (pattern[i - 1] || 0)
            ).toFixed(2);
            results.push({ time, value });
         }
      }
      return results;
   };

   const filterUniqueByTime = (array) => {
      return array.filter(
         (item, index, self) =>
            index === self.findIndex((t) => t.time === item.time),
      );
   };

   // const calculatePriceData4h = () => {}

   const calculatePriceData1h = (
      startPrice,
      endPrice,
      resultTimestamp,
      template = defaultTemplate,
   ) => {
      let results = [];
      const diffPrice =
         (endPrice - startPrice) / (resultTimestamp?.fourHour.length - 1);
      for (let index = 0; index < resultTimestamp?.fourHour.length; index++) {
         if (index !== 0) {
            results = [
               ...results,
               ...generatePrice(
                  [
                     resultTimestamp?.fourHour[index - 1],
                     resultTimestamp?.fourHour[index],
                  ],
                  TEMPLATES[template].fourHourPatterns[
                     getRandomInt(
                        0,
                        TEMPLATES[template].fourHourPatterns.length - 1,
                        `4h-${index}`,
                     )
                  ],
                  startPrice + diffPrice * (index - 1),
                  startPrice + diffPrice * index,
               ),
            ];
         }
      }
      return results;
   };

   const calculatePriceData15m = (oneHourValue, template = defaultTemplate) => {
      let results = [];

      for (let index = 0; index < oneHourValue.length; index++) {
         if (index !== 0) {
            results = [
               ...results,
               ...generatePrice(
                  [oneHourValue[index - 1].time, oneHourValue[index].time],
                  TEMPLATES[template].oneHourPatterns[
                     getRandomInt(
                        0,
                        TEMPLATES[template].oneHourPatterns.length - 1,
                        `1h-${index}`,
                     )
                  ],
                  oneHourValue[index - 1].value,
                  oneHourValue[index].value,
               ),
            ];
         }
      }
      return results;
   };

   const calculatePriceData5m = (
      fifteenMinuteValue,
      template = defaultTemplate,
   ) => {
      let results = [];
      for (let index = 0; index < fifteenMinuteValue.length; index++) {
         if (index !== 0) {
            results = [
               ...results,
               ...generatePrice(
                  [
                     fifteenMinuteValue[index - 1].time,
                     fifteenMinuteValue[index].time,
                  ],
                  TEMPLATES[template].fifteenMinutePatterns[
                     getRandomInt(
                        0,
                        TEMPLATES[template].fifteenMinutePatterns.length - 1,
                        `15m-${index}`,
                     )
                  ],
                  fifteenMinuteValue[index - 1].value,
                  fifteenMinuteValue[index].value,
               ),
            ];
         }
      }
      return results;
   };

   const calculatePriceData1m = (
      fiveMinuteValue,
      template = defaultTemplate,
   ) => {
      let results = [];
      for (let index = 0; index < fiveMinuteValue.length; index++) {
         if (index !== 0) {
            results = [
               ...results,
               ...generatePrice(
                  [
                     fiveMinuteValue[index - 1].time,
                     fiveMinuteValue[index].time,
                  ],
                  TEMPLATES[template].fiveMinutePatterns[
                     getRandomInt(
                        0,
                        TEMPLATES[template].fiveMinutePatterns.length - 1,
                        `5m-${index}`,
                     )
                  ],
                  fiveMinuteValue[index - 1].value,
                  fiveMinuteValue[index].value,
               ),
            ];
         }
      }
      return results;
   };

   const calculatePriceData10s = (
      oneMinuteValue,
      template = defaultTemplate,
   ) => {
      let results = [];
      for (let index = 0; index < oneMinuteValue.length; index++) {
         if (index !== 0) {
            results = [
               ...results,
               ...generatePrice(
                  [oneMinuteValue[index - 1].time, oneMinuteValue[index].time],
                  TEMPLATES[template].oneMinutePatterns[
                     getRandomInt(
                        0,
                        TEMPLATES[template].oneMinutePatterns.length - 1,
                        `1m-${index}`,
                     )
                  ],
                  oneMinuteValue[index - 1].value,
                  oneMinuteValue[index].value,
               ),
            ];
         }
      }
      return results;
   };

   const generateChartData = (resultTimestamp) => {
      const fourHourValue = filterUniqueByTime(
         calculatePriceData1h(
            general_Form.getFieldValue('startPrice'),
            general_Form.getFieldValue('endPrice'),
            resultTimestamp,
         ),
      );

      const oneHourValue = filterUniqueByTime(
         calculatePriceData15m(fourHourValue),
      );

      const fifteenMinuteValue = filterUniqueByTime(
         calculatePriceData5m(oneHourValue),
      );

      const fiveMinuteValue = filterUniqueByTime(
         calculatePriceData1m(fifteenMinuteValue),
      );

      const oneMinuteValue = filterUniqueByTime(
         calculatePriceData10s(fiveMinuteValue),
      );

      const formValue = oneHourValue.reduce((acc, item) => {
         acc[item.time] = item.value;
         return acc;
      }, {});

      trend_Form.setFieldsValue(formValue);

      setDataChart({
         fourHourValue,
         oneHourValue,
         fifteenMinuteValue,
         fiveMinuteValue,
         oneMinuteValue,
      });
   };

   const generateData = async (allValues) => {
      createLoading();
      await delay(100);
      trend_Form.resetFields();
      const resultTimestamp = generateTimestamps(allValues?.date);
      if (resultTimestamp?.fourHour.length) {
         generateChartData(resultTimestamp);
      }
   };

   const rangeDataFilter = (array, startTimestamp, endTimestamp) => {
      return array.filter(
         (item) => item.time >= startTimestamp && item.time <= endTimestamp,
      );
   };

   const filterAndFindMinMaxCandle = (
      rangeData = [],
      startTimestamp,
      endTimestamp,
   ) => {
      const filteredData = rangeDataFilter(
         rangeData,
         startTimestamp,
         endTimestamp,
      );

      if (!filteredData?.length) {
         return null;
      }

      const minValue = Math.min(...filteredData.map((item) => item.value));
      const maxValue = Math.max(...filteredData.map((item) => item.value));

      return {
         minValue,
         maxValue,
      };
   };

   useEffect(() => {
      switch (groupCandle) {
         case '4h':
            setCurrentDataChart(dataChart?.fourHourValue);
            break;
         case '1h':
            setCurrentDataChart(dataChart?.fourHourValue);
            break;
         case '15m':
            setCurrentDataChart(dataChart?.oneHourValue);

            break;
         case '5m':
            setCurrentDataChart(dataChart?.fifteenMinuteValue);
            break;
         default:
            setCurrentDataChart(dataChart?.fiveMinuteValue);
            break;
      }
      if (chartsController && dataChart?.fourHourValue?.length) {
         setTimeout(() => {
            chartsController.chart.timeScale().fitContent();
         }, 100);
      }
   }, [dataChart]);

   useEffect(() => {
      if (!chartsController) return;
      if (!currentDataChart?.length) return;
      if (typeChart === 'line') {
         chartsController.candlestickSeries.setData([]);
         chartsController.lineSeries.setData(
            currentDataChart.map((item) => ({
               time: moment
                  .unix(item.time / 1000)
                  .add(7, 'hours')
                  .unix(),
               value: item.value,
            })),
         );
         chartsController.candlestickSeries.setData([]);
      } else {
         const chartData = currentDataChart;
         const result = chartData.map((item, index) => {
            return {
               ...item,
               time: moment
                  .unix(item.time / 1000)
                  .add(7, 'hours')
                  .unix(),
               open: index === 0 ? item.value : chartData[index - 1].value,
               high:
                  index === 0
                     ? item.value
                     : filterAndFindMinMaxCandle(
                          dataChart?.oneMinuteValue,
                          chartData[index - 1].time,
                          chartData[index].time,
                       )?.maxValue,
               low:
                  index === 0
                     ? item.value
                     : filterAndFindMinMaxCandle(
                          dataChart?.oneMinuteValue,
                          chartData[index - 1].time,
                          chartData[index].time,
                       )?.minValue,
               close: index === 0 ? item.value : chartData[index].value,
            };
         });
         chartsController.lineSeries.setData([]);
         chartsController.candlestickSeries.setData(result);
      }
   }, [currentDataChart, typeChart]);

   useEffect(() => {
      const handleResize = () => {
         chart.applyOptions({
            width: chartContainerRef.current?.clientWidth,
         });
      };

      const chart = createChart(chartContainerRef.current, {
         layout: {
            background: { type: ColorType.Solid, color: 'white' },
            textColor: 'black',
         },
         width: chartContainerRef.current?.clientWidth,
         height: chartContainerRef.current?.clientHeight,
         timeScale: {
            timeVisible: true,
            secondsVisible: true,
            allowShiftVisibleRangeOnWhitespaceReplacement: true,
         },
         localization: {
            dateFormat: 'dd/MM/yyyy',
         },
         // dateRanges: [
         //    '1d|1',
         //    '1m|30',
         //    '3m|60',
         //    '12m|1D',
         //    '60m|1W',
         //    'all|1M',
         // ],
         // localization: {
         //    dateFormat: 'HH:mm DD-MM-YYYY',
         // },
      });

      chartRef.current = chart;

      const lineSeries = chart.addLineSeries({ color: '#2C88D9' });

      seriesRef.current = lineSeries;

      const candlestickSeries = chart.addCandlestickSeries({
         upColor: '#4CAF50',
         downColor: '#FF5252',
         borderVisible: false,
         wickUpColor: '#4CAF50',
         wickDownColor: '#FF5252',
      });

      candlestickSeries.setData([]);
      lineSeries.setData([]);

      setChartsController({
         lineSeries,
         candlestickSeries,
         chart,
      });

      window.addEventListener('resize', handleResize);

      return () => {
         window.removeEventListener('resize', handleResize);
         chart.remove();
      };
   }, []);

   const handleChangeGeneral = useCallback(
      debounce((_, allValues) => {
         general_Form
            .validateFields()
            .then(() => {
               generateData(allValues);
            })
            .catch((error) => {
               if (!error?.errorFields?.length) {
                  generateData(allValues);
               } else {
                  resetAllState();
               }
            });
      }, 500),
      [],
   );

   const replaceItemsByTime = (array1, array2) => {
      return array1.map((item1) => {
         const matchingItem = array2.find((item2) => item2.time === item1.time);
         return matchingItem ? matchingItem : item1;
      });
   };

   const handleAmountChange = (dataModify, value) => {
      if (changedFieldRef.current === '4h') {
         const index = timestamps.fourHour.indexOf(+dataModify);
         // trend_Form.setFieldsValue({
         //    [`template-4h-${dataModify}`]: defaultTemplate,
         //    [`template-1h-${dataModify}`]: defaultTemplate,
         //    [`template-15m-${dataModify}`]: defaultTemplate,
         //    [`template-4h-${timestamps.fourHour[index - 1]}`]: defaultTemplate,
         //    [`template-1h-${timestamps.fourHour[index - 1]}`]: defaultTemplate,
         //    [`template-15m-${timestamps.fourHour[index - 1]}`]: defaultTemplate,
         // });

         const range1 = rangeDataFilter(
            dataChart.fourHourValue,
            timestamps.fourHour[index - 1],
            timestamps.fourHour[index + 1],
         );

         const fourHourValue1 = filterUniqueByTime(
            calculatePriceData1h(range1[0].value, value, {
               fourHour: [
                  timestamps.fourHour[index - 1],
                  timestamps.fourHour[index],
               ],
            }),
         );

         const fourHourValue2 = filterUniqueByTime(
            calculatePriceData1h(value, range1[range1.length - 1].value, {
               fourHour: [
                  timestamps.fourHour[index],
                  timestamps.fourHour[index + 1],
               ],
            }),
         );

         const newFourHour = filterUniqueByTime([
            ...fourHourValue1,
            ...fourHourValue2,
         ]);

         const newOneHourValue = filterUniqueByTime(
            calculatePriceData15m(newFourHour),
         );

         const newFifteenMinuteValue = filterUniqueByTime(
            calculatePriceData5m(newOneHourValue),
         );

         const newFiveMinuteValue = filterUniqueByTime(
            calculatePriceData1m(newFifteenMinuteValue),
         );

         const newOneMinuteValue = filterUniqueByTime(
            calculatePriceData10s(newFiveMinuteValue),
         );

         setDataChart((prevValue) => {
            const newfourHourValue = replaceItemsByTime(
               prevValue?.fourHourValue,
               newFourHour,
            );
            const newoneHourValue = replaceItemsByTime(
               prevValue?.oneHourValue,
               newOneHourValue,
            );

            const formValue = newoneHourValue.reduce((acc, item) => {
               acc[item.time] = item.value;
               return acc;
            }, {});

            trend_Form.setFieldsValue(formValue);

            const newfifteenMinuteValue = replaceItemsByTime(
               prevValue?.fifteenMinuteValue,
               newFifteenMinuteValue,
            );
            const newfiveMinuteValue = replaceItemsByTime(
               prevValue?.fiveMinuteValue,
               newFiveMinuteValue,
            );
            const newoneMinuteValue = replaceItemsByTime(
               prevValue?.oneMinuteValue,
               newOneMinuteValue,
            );
            return {
               fourHourValue: newfourHourValue,
               oneHourValue: newoneHourValue,
               fifteenMinuteValue: newfifteenMinuteValue,
               fiveMinuteValue: newfiveMinuteValue,
               oneMinuteValue: newoneMinuteValue,
            };
         });

         return;
      }
      if (changedFieldRef.current === '1h') {
         // trend_Form.setFieldsValue({
         //    [`template-1h-${dataModify}`]: defaultTemplate,
         //    [`template-15m-${dataModify}`]: defaultTemplate,
         //    [`template-1h-${timestamps.oneHour[index - 1]}`]: defaultTemplate,
         //    [`template-15m-${timestamps.oneHour[index - 1]}`]: defaultTemplate,
         // });

         const [lower, _] = findClosestNumbers(
            timestamps.fourHour,
            +dataModify,
         );

         trend_Form.setFieldsValue({
            [`template-4h-${lower}`]: 'custom',
         });

         const index = timestamps.oneHour.indexOf(+dataModify);

         const rangeModify = rangeDataFilter(
            dataChart.fourHourValue,
            timestamps.oneHour[index - 1],
            timestamps.oneHour[index + 1],
         );

         const newRangeModify = rangeModify.map((item) => {
            if (item.time === +dataModify) {
               return {
                  ...item,
                  value,
               };
            }
            return {
               ...item,
            };
         });

         const newOneHourValue = filterUniqueByTime(
            calculatePriceData15m(newRangeModify),
         );

         const newFifteenMinuteValue = filterUniqueByTime(
            calculatePriceData5m(newOneHourValue),
         );

         const newFiveMinuteValue = filterUniqueByTime(
            calculatePriceData1m(newFifteenMinuteValue),
         );

         const newOneMinuteValue = filterUniqueByTime(
            calculatePriceData10s(newFiveMinuteValue),
         );

         setDataChart((prevValue) => {
            const newoneHourValue = replaceItemsByTime(
               prevValue?.oneHourValue,
               newOneHourValue,
            );

            const formValue = newoneHourValue.reduce((acc, item) => {
               acc[item.time] = item.value;
               return acc;
            }, {});

            trend_Form.setFieldsValue(formValue);

            const newfifteenMinuteValue = replaceItemsByTime(
               prevValue?.fifteenMinuteValue,
               newFifteenMinuteValue,
            );

            const newfiveMinuteValue = replaceItemsByTime(
               prevValue?.fiveMinuteValue,
               newFiveMinuteValue,
            );

            const newoneMinuteValue = replaceItemsByTime(
               prevValue?.oneMinuteValue,
               newOneMinuteValue,
            );

            const newFourHourValue = replaceItemsByTime(
               prevValue?.fourHourValue,
               newRangeModify,
            );

            return {
               fourHourValue: newFourHourValue,
               oneHourValue: newoneHourValue,
               fifteenMinuteValue: newfifteenMinuteValue,
               fiveMinuteValue: newfiveMinuteValue,
               oneMinuteValue: newoneMinuteValue,
            };
         });

         return;
      }
      if (changedFieldRef.current === '15m') {
         const index = timestamps.fifteenMinutes.indexOf(+dataModify);

         const [lower, _] = findClosestNumbers(timestamps.oneHour, +dataModify);

         trend_Form.setFieldsValue({
            [`template-1h-${lower}`]: 'custom',
         });

         const rangeModify = rangeDataFilter(
            dataChart.oneHourValue,
            timestamps.fifteenMinutes[index - 1],
            timestamps.fifteenMinutes[index + 1],
         );

         const newRangeModify = rangeModify.map((item) => {
            if (item.time === +dataModify) {
               return {
                  ...item,
                  value,
               };
            }
            return {
               ...item,
            };
         });

         const newFifteenMinuteValue = filterUniqueByTime(
            calculatePriceData5m(newRangeModify),
         );

         const newFiveMinuteValue = filterUniqueByTime(
            calculatePriceData1m(newFifteenMinuteValue),
         );

         const newOneMinuteValue = filterUniqueByTime(
            calculatePriceData10s(newFiveMinuteValue),
         );

         setDataChart((prevValue) => {
            const newfifteenMinuteValue = replaceItemsByTime(
               prevValue?.fifteenMinuteValue,
               newFifteenMinuteValue,
            );
            const newfiveMinuteValue = replaceItemsByTime(
               prevValue?.fiveMinuteValue,
               newFiveMinuteValue,
            );
            const newoneMinuteValue = replaceItemsByTime(
               prevValue?.oneMinuteValue,
               newOneMinuteValue,
            );

            return {
               fourHourValue: prevValue?.fourHourValue,
               oneHourValue: replaceItemsByTime(
                  prevValue?.oneHourValue,
                  newRangeModify,
               ),
               fifteenMinuteValue: newfifteenMinuteValue,
               fiveMinuteValue: newfiveMinuteValue,
               oneMinuteValue: newoneMinuteValue,
            };
         });

         return;
      }
   };

   const handleTemplateChange = async (dataModify, value) => {
      const [_, str2, str3] = dataModify.split('-');
      if (!str3 || !str2) return;

      createLoading();
      await delay(100);

      if (str2 === '4h') {
         const index = timestamps.fourHour.indexOf(+str3);
         const range = rangeDataFilter(
            dataChart.fourHourValue,
            timestamps.fourHour[index],
            timestamps.fourHour[index + 1],
         );

         const newFourHour = filterUniqueByTime(
            calculatePriceData1h(
               range[0].value,
               range[range.length - 1].value,
               {
                  fourHour: [
                     timestamps.fourHour[index],
                     timestamps.fourHour[index + 1],
                  ],
               },
               value,
            ),
         );

         const newOneHourValue = filterUniqueByTime(
            calculatePriceData15m(newFourHour),
         );

         const newFifteenMinuteValue = filterUniqueByTime(
            calculatePriceData5m(newOneHourValue),
         );

         const newFiveMinuteValue = filterUniqueByTime(
            calculatePriceData1m(newFifteenMinuteValue),
         );

         const newOneMinuteValue = filterUniqueByTime(
            calculatePriceData10s(newFiveMinuteValue),
         );

         setDataChart((prevValue) => {
            const newfourHourValue = replaceItemsByTime(
               prevValue?.fourHourValue,
               newFourHour,
            );
            const newoneHourValue = replaceItemsByTime(
               prevValue?.oneHourValue,
               newOneHourValue,
            );

            const formValue = newoneHourValue.reduce((acc, item) => {
               acc[item.time] = item.value;
               return acc;
            }, {});

            trend_Form.setFieldsValue(formValue);

            const newfifteenMinuteValue = replaceItemsByTime(
               prevValue?.fifteenMinuteValue,
               newFifteenMinuteValue,
            );
            const newfiveMinuteValue = replaceItemsByTime(
               prevValue?.fiveMinuteValue,
               newFiveMinuteValue,
            );
            const newoneMinuteValue = replaceItemsByTime(
               prevValue?.oneMinuteValue,
               newOneMinuteValue,
            );
            return {
               fourHourValue: newfourHourValue,
               oneHourValue: newoneHourValue,
               fifteenMinuteValue: newfifteenMinuteValue,
               fiveMinuteValue: newfiveMinuteValue,
               oneMinuteValue: newoneMinuteValue,
            };
         });

         return;
      }

      if (str2 === '1h') {
         const index = timestamps.oneHour.indexOf(+str3);
         const rangeModify = rangeDataFilter(
            dataChart.fourHourValue,
            timestamps.oneHour[index],
            timestamps.oneHour[index + 1],
         );

         const newOneHourValue = filterUniqueByTime(
            calculatePriceData15m(rangeModify, value),
         );

         const newFifteenMinuteValue = filterUniqueByTime(
            calculatePriceData5m(newOneHourValue),
         );

         const newFiveMinuteValue = filterUniqueByTime(
            calculatePriceData1m(newFifteenMinuteValue),
         );

         const newOneMinuteValue = filterUniqueByTime(
            calculatePriceData10s(newFiveMinuteValue),
         );

         setDataChart((prevValue) => {
            const newoneHourValue = replaceItemsByTime(
               prevValue?.oneHourValue,
               newOneHourValue,
            );

            const formValue = newoneHourValue.reduce((acc, item) => {
               acc[item.time] = item.value;
               return acc;
            }, {});

            trend_Form.setFieldsValue(formValue);

            const newfifteenMinuteValue = replaceItemsByTime(
               prevValue?.fifteenMinuteValue,
               newFifteenMinuteValue,
            );
            const newfiveMinuteValue = replaceItemsByTime(
               prevValue?.fiveMinuteValue,
               newFiveMinuteValue,
            );
            const newoneMinuteValue = replaceItemsByTime(
               prevValue?.oneMinuteValue,
               newOneMinuteValue,
            );
            
            return {
               fourHourValue: prevValue?.fourHourValue,
               oneHourValue: newoneHourValue,
               fifteenMinuteValue: newfifteenMinuteValue,
               fiveMinuteValue: newfiveMinuteValue,
               oneMinuteValue: newoneMinuteValue,
            };
         });

         return;
      }

      if (str2 === '15m') {
         const index = timestamps.fifteenMinutes.indexOf(+str3);
         const rangeModify = rangeDataFilter(
            dataChart.oneHourValue,
            timestamps.fifteenMinutes[index],
            timestamps.fifteenMinutes[index + 1],
         );

         const newFifteenMinuteValue = filterUniqueByTime(
            calculatePriceData5m(rangeModify, value),
         );

         const newFiveMinuteValue = filterUniqueByTime(
            calculatePriceData1m(newFifteenMinuteValue),
         );

         const newOneMinuteValue = filterUniqueByTime(
            calculatePriceData10s(newFiveMinuteValue),
         );

         setDataChart((prevValue) => {
            const newfifteenMinuteValue = replaceItemsByTime(
               prevValue?.fifteenMinuteValue,
               newFifteenMinuteValue,
            );
            const newfiveMinuteValue = replaceItemsByTime(
               prevValue?.fiveMinuteValue,
               newFiveMinuteValue,
            );
            const newoneMinuteValue = replaceItemsByTime(
               prevValue?.oneMinuteValue,
               newOneMinuteValue,
            );
            return {
               fourHourValue: prevValue?.fourHourValue,
               oneHourValue: prevValue?.oneHourValue,
               fifteenMinuteValue: newfifteenMinuteValue,
               fiveMinuteValue: newfiveMinuteValue,
               oneMinuteValue: newoneMinuteValue,
            };
         });

         return;
      }
   };

   const handleChangeTrend = useCallback(
      debounce((changedValue) => {
         const dataModify = Object.keys(changedValue)[0];
         const value = Object.values(changedValue)[0];
         if (dataModify.includes('template-')) {
            handleTemplateChange(dataModify, value);
         } else {
            handleAmountChange(dataModify, +value);
         }
      }, 1000),
      [timestamps, dataChart],
   );

   const disabledDate = (current) => {
      return current && current < moment().startOf('day');
   };

   const formatTimestamp = (timestamp) =>
      moment(timestamp).format('HH:mm:ss DD-MM-YYYY');

   const downloadJSON = (data) => {
      const filename = `AMM-${moment().format('HH:mm:ss DD-MM-YYYY')}`;
      const blob = new Blob([JSON.stringify(data, null, 2)], {
         type: 'application/json',
      });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
   };

   const roundOrder = (input) => {
      return Math.round(input * 1e8) / 1e8;
   };

   const currentPool = (price, constant_k) => {
      const token_0 =
         general_Form.getFieldValue('liquidityEth') * Math.sqrt(price);
      const token_1 = constant_k / token_0;

      return {
         token_0: token_0,
         token_1: token_1,
      };
   };

   const generateOrders = () => {
      const initialToken0 = general_Form.getFieldValue('liquidityEth');
      const initialToken1 = general_Form.getFieldValue('liquidityToken');

      if (!dataChart?.oneMinuteValue || !initialToken0 || !initialToken1) {
         return;
      }

      const output = [];

      const constant_k = initialToken0 * initialToken1;

      for (let i = 0; i < dataChart?.oneMinuteValue.length - 1; i++) {
         const currentValue = dataChart?.oneMinuteValue[i].value;
         const nextValue = dataChart?.oneMinuteValue[i + 1].value;

         if (currentValue < nextValue) {
            const amountToBuy = Math.max(
               0,
               roundOrder(
                  currentPool(nextValue, constant_k).token_0 -
                     currentPool(currentValue, constant_k).token_0,
               ),
            );
            output.push({
               transactionType: 'buy',
               amount: amountToBuy,
            });
         } else if (currentValue > nextValue) {
            const amountToSell = Math.max(
               0,
               roundOrder(
                  currentPool(nextValue, constant_k).token_1 -
                     currentPool(currentValue, constant_k).token_1,
               ),
            );
            output.push({
               transactionType: 'sell',
               amount: amountToSell,
            });
         }
      }
      downloadJSON(output);
      return output;
   };

   return (
      <div className="mx-8 my-4">
         <Card className="w-full">
            <div className="flex mb-[20px] gap-3 h-[calc(100vh-80px-70px-16px-16px-24px-24px-24px)]">
               <div className="basis-1/3  overflow-y-scroll overflow-x-hidden">
                  <div className="general">
                     <Title className="!mb-4" level={2}>
                        General
                     </Title>
                     <Form
                        name="mm_general"
                        // disabled={isLoading}
                        layout="vertical"
                        form={general_Form}
                        onValuesChange={handleChangeGeneral}
                     >
                        <Row gutter={20}>
                           <Col span={12}>
                              <Form.Item
                                 label="Liquidity ETH"
                                 name="liquidityEth"
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Please input Liquidity ETH!',
                                    },
                                 ]}
                              >
                                 <InputNumberComponent
                                    size="large"
                                    placeholder="Liquidity ETH"
                                 />
                              </Form.Item>
                           </Col>
                           <Col span={12}>
                              <Form.Item
                                 label="Liquidity Token"
                                 name="liquidityToken"
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Please input Liquidity Token!',
                                    },
                                 ]}
                              >
                                 <InputNumberComponent
                                    size="large"
                                    placeholder="Liquidity Token"
                                 />
                              </Form.Item>
                           </Col>
                        </Row>
                        <Form.Item
                           className="w-full"
                           name="date"
                           label="Start Date and End Date"
                           rules={[
                              // {
                              //    required: true,
                              //    message:
                              //       'Please input Start Date and End Date!',
                              // },
                              {
                                 validator: validateDateRange,
                              },
                           ]}
                        >
                           <RangePicker
                              ref={rangePickerRef}
                              autoFocus={false}
                              className="w-full"
                              showTime={{
                                 format: 'HH',
                              }}
                              needConfirm={false}
                              // hideDisabledOptions={false}
                              format="HH:mm DD-MM-YYYY"
                              hourStep={4}
                              disabledDate={disabledDate}
                              // disabledTime={disabledTime}
                           />
                        </Form.Item>
                        <Row gutter={20}>
                           <Col span={12}>
                              <Form.Item
                                 label="Start Price"
                                 name="startPrice"
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Please input Start Price!',
                                    },
                                 ]}
                              >
                                 <InputNumberComponent
                                    allowDecimal
                                    disabled={isLoading}
                                    placeholder="Start Price"
                                 />
                              </Form.Item>
                           </Col>
                           <Col span={12}>
                              <Form.Item
                                 label="End Price"
                                 name="endPrice"
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Please input End Price!',
                                    },
                                 ]}
                              >
                                 <InputNumberComponent
                                    disabled={isLoading}
                                    allowDecimal
                                    placeholder="End Price"
                                 />
                              </Form.Item>
                           </Col>
                        </Row>
                     </Form>
                  </div>
                  <div className="trends">
                     {!!timestamps?.fourHour?.length && (
                        <Form
                           name="mm_tr"
                           disabled={isLoading}
                           layout="vertical"
                           onValuesChange={handleChangeTrend}
                           form={trend_Form}
                        >
                           <Title className="!mb-4" level={2}>
                              Trends
                           </Title>
                           <div className="flex gap-3">
                              <div className="basis-1/3">
                                 <Title className="!mb-3" level={4}>
                                    Trend 4h
                                 </Title>
                                 <div className="flex flex-col gap-3 items-center ">
                                    <Form.Item
                                       className="w-full"
                                       label={formatTimestamp(
                                          timestamps?.fourHour[0],
                                       )}
                                       name={timestamps?.fourHour[0]}
                                       rules={[
                                          {
                                             required: true,
                                             message: 'Please input Trend!',
                                          },
                                       ]}
                                    >
                                       <InputNumberComponent
                                          allowDecimal
                                          disabled
                                          placeholder="Input Trend"
                                       />
                                    </Form.Item>
                                    <ArrowDownOutlined />
                                    <Form.Item
                                       className="w-full mb-7"
                                       name={`template-4h-${timestamps?.fourHour[0]}`}
                                       rules={[
                                          {
                                             required: true,
                                             message:
                                                'Please choose the Template!',
                                          },
                                       ]}
                                    >
                                       <TemplateSelector />
                                    </Form.Item>

                                    {timestamps?.fourHour
                                       .slice(1)
                                       .map((timestamp, index) => (
                                          <>
                                             <ArrowDownOutlined />
                                             <Form.Item
                                                className="w-full"
                                                label={formatTimestamp(
                                                   timestamp,
                                                )}
                                                name={timestamp}
                                                rules={[
                                                   {
                                                      required: true,
                                                      message:
                                                         'Please input Trend!',
                                                   },
                                                ]}
                                             >
                                                <InputNumberComponent
                                                   disabled={
                                                      index ===
                                                      timestamps?.fourHour
                                                         .length -
                                                         2
                                                   }
                                                   allowDecimal
                                                   placeholder="Input Trend"
                                                   onChange={() =>
                                                      (changedFieldRef.current =
                                                         '4h')
                                                   }
                                                />
                                             </Form.Item>
                                             {index !==
                                                timestamps?.fourHour.length -
                                                   2 && (
                                                <>
                                                   <ArrowDownOutlined />
                                                   <Form.Item
                                                      className="w-full"
                                                      name={`template-4h-${timestamp}`}
                                                      rules={[
                                                         {
                                                            required: true,
                                                            message:
                                                               'Please choose the Template!',
                                                         },
                                                      ]}
                                                   >
                                                      <TemplateSelector />
                                                   </Form.Item>
                                                </>
                                             )}
                                          </>
                                       ))}
                                 </div>
                              </div>
                              <div className="basis-1/3">
                                 <Title className="!mb-3" level={4}>
                                    Trend 1h
                                 </Title>
                                 {!!timestamps?.oneHour?.length && (
                                    <div className="flex flex-col gap-3 items-center ">
                                       <Form.Item
                                          className="w-full"
                                          label={formatTimestamp(
                                             timestamps?.oneHour[0],
                                          )}
                                          name={timestamps?.oneHour[0]}
                                          rules={[
                                             {
                                                required: true,
                                                message: 'Please input Trend!',
                                             },
                                          ]}
                                       >
                                          <InputNumberComponent
                                             disabled
                                             allowDecimal
                                             placeholder="Input Trend"
                                          />
                                       </Form.Item>
                                       <ArrowDownOutlined />
                                       <Form.Item
                                          className="w-full mb-7"
                                          name={`template-1h-${timestamps?.oneHour[0]}`}
                                          rules={[
                                             {
                                                required: true,
                                                message:
                                                   'Please choose the Template!',
                                             },
                                          ]}
                                       >
                                          <TemplateSelector />
                                       </Form.Item>

                                       {timestamps?.oneHour
                                          .slice(1)
                                          .map((timestamp, index) => (
                                             <>
                                                <ArrowDownOutlined />
                                                <Form.Item
                                                   className="w-full"
                                                   label={formatTimestamp(
                                                      timestamp,
                                                   )}
                                                   name={timestamp}
                                                   rules={[
                                                      {
                                                         required: true,
                                                         message:
                                                            'Please input Trend!',
                                                      },
                                                   ]}
                                                >
                                                   <InputNumberComponent
                                                      allowDecimal
                                                      disabled={
                                                         index ===
                                                         timestamps?.oneHour
                                                            .length -
                                                            2
                                                      }
                                                      placeholder="Input Trend"
                                                      onChange={() =>
                                                         (changedFieldRef.current =
                                                            '1h')
                                                      }
                                                   />
                                                </Form.Item>
                                                {index !==
                                                   timestamps?.oneHour.length -
                                                      2 && (
                                                   <>
                                                      <ArrowDownOutlined />
                                                      <Form.Item
                                                         className="w-full"
                                                         name={`template-1h-${timestamp}`}
                                                         rules={[
                                                            {
                                                               required: true,
                                                               message:
                                                                  'Please choose the Template!',
                                                            },
                                                         ]}
                                                      >
                                                         <TemplateSelector />
                                                      </Form.Item>
                                                   </>
                                                )}
                                             </>
                                          ))}
                                    </div>
                                 )}
                              </div>
                              <div className="basis-1/3">
                                 <Title className="!mb-3" level={4}>
                                    Trend 15m
                                 </Title>
                                 {!!timestamps?.fifteenMinutes?.length && (
                                    <div className="flex flex-col gap-3 items-center ">
                                       <Form.Item
                                          className="w-full"
                                          label={formatTimestamp(
                                             timestamps?.fifteenMinutes[0],
                                          )}
                                          name={timestamps?.fifteenMinutes[0]}
                                          rules={[
                                             {
                                                required: true,
                                                message: 'Please input Trend!',
                                             },
                                          ]}
                                       >
                                          <InputNumberComponent
                                             allowDecimal
                                             disabled
                                             placeholder="Input Trend"
                                          />
                                       </Form.Item>
                                       <ArrowDownOutlined />
                                       <Form.Item
                                          className="w-full mb-7"
                                          name={`template-15m-${timestamps?.fifteenMinutes[0]}`}
                                          rules={[
                                             {
                                                required: true,
                                                message:
                                                   'Please choose the Template!',
                                             },
                                          ]}
                                       >
                                          <TemplateSelector />
                                       </Form.Item>

                                       {timestamps?.fifteenMinutes
                                          .slice(1)
                                          .map((timestamp, index) => (
                                             <>
                                                <ArrowDownOutlined />
                                                <Form.Item
                                                   className="w-full"
                                                   label={formatTimestamp(
                                                      timestamp,
                                                   )}
                                                   name={timestamp}
                                                   rules={[
                                                      {
                                                         required: true,
                                                         message:
                                                            'Please input Trend!',
                                                      },
                                                   ]}
                                                >
                                                   <InputNumberComponent
                                                      allowDecimal
                                                      disabled={
                                                         index ===
                                                         timestamps
                                                            .fifteenMinutes
                                                            .length -
                                                            2
                                                      }
                                                      placeholder="Input Trend"
                                                      onChange={() =>
                                                         (changedFieldRef.current =
                                                            '15m')
                                                      }
                                                   />
                                                </Form.Item>
                                                {index !==
                                                   timestamps?.fifteenMinutes
                                                      .length -
                                                      2 && (
                                                   <>
                                                      <ArrowDownOutlined />
                                                      <Form.Item
                                                         className="w-full"
                                                         name={`template-15m-${timestamp}`}
                                                         rules={[
                                                            {
                                                               required: true,
                                                               message:
                                                                  'Please choose the Template!',
                                                            },
                                                         ]}
                                                      >
                                                         <TemplateSelector />
                                                      </Form.Item>
                                                   </>
                                                )}
                                             </>
                                          ))}
                                    </div>
                                 )}
                              </div>
                           </div>
                        </Form>
                     )}
                  </div>
               </div>
               <div className="basis-2/3 flex flex-col items-center relative">
                  <div
                     className={`absolute top-[62px] left-0 z-50 ${dataChart?.fiveMinuteValue?.length ? '' : 'invisible'} flex items-center gap-3`}
                  >
                     <Segmented
                        options={['4h', '1h', '15m', '5m', '1m']}
                        value={groupCandle}
                        onChange={async (value) => {
                           // createLoading(1000);
                           // await delay(10);
                           switch (value) {
                              case '4h':
                                 setCurrentDataChart(dataChart?.fourHourValue);
                                 break;
                              case '1h':
                                 setCurrentDataChart(dataChart?.fourHourValue);
                                 break;
                              case '15m':
                                 setCurrentDataChart(dataChart?.oneHourValue);
                                 break;
                              case '5m':
                                 setCurrentDataChart(
                                    dataChart?.fifteenMinuteValue,
                                 );
                                 break;
                              default:
                                 setCurrentDataChart(
                                    dataChart?.fiveMinuteValue,
                                 );
                                 break;
                           }
                           if (chartsController) {
                              chartsController.chart.timeScale().fitContent();
                           }
                           setGroupCandle(value);
                        }}
                     />
                  </div>
                  <div
                     className={`my-3 w-full flex gap-1 justify-end items-center font-semibold ${dataChart?.fiveMinuteValue?.length ? '' : 'invisible'}`}
                  >
                     <Button className="mr-4" onClick={() => generateOrders()}>
                        Generate File
                     </Button>
                     <Radio.Group
                        className="gap-2"
                        onChange={async (e) => {
                           // createLoading();
                           // await delay(100);
                           setTypeChart(e.target.value);
                        }}
                        value={typeChart}
                     >
                        <Radio value="line">Line</Radio>
                        <Radio value="candle">Candle</Radio>
                     </Radio.Group>
                     <Checkbox
                        value={showSnipe}
                        onChange={async (e) => {
                           // createLoading();
                           // await delay(100);
                           const snipeValue = e.target.checked;
                           if (snipeValue) {
                              setCurrentDataChart((prevLineChartData) => {
                                 const firstItem = prevLineChartData[0];
                                 const newTime = firstItem.time - 1;

                                 const newItem = {
                                    time: newTime,
                                    value: 0,
                                 };

                                 return [newItem, ...prevLineChartData];
                              });
                           } else {
                              setCurrentDataChart((prevLineChartData) => {
                                 return prevLineChartData.slice(1);
                              });
                           }
                           setShowSnipe(e.target.checked);
                        }}
                     >
                        Show Snipe
                     </Checkbox>
                  </div>
                  <div
                     className={`h-full w-full ${dataChart?.fiveMinuteValue?.length ? '' : 'invisible'}`}
                     ref={chartContainerRef}
                  />
                  {!dataChart?.fiveMinuteValue?.length && (
                     <Empty
                        className="w-full h-full flex flex-col justify-center items-center absolute "
                        description="No Chart Data"
                     />
                  )}
               </div>
            </div>
         </Card>
         <LoadingComponent
            fullscreen
            loading={isLoading}
            text="Generating..."
         />
      </div>
   );
};

export default ChartGenerator2Component;
