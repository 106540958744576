export const findClosestNumbers = (arr: number[], target: number) => {
   let lower = null;
   let upper = null;

   for (let i = 0; i < arr.length; i++) {
      if (arr[i] <= target) {
         lower = arr[i];
      }
      if (arr[i] >= target && upper === null) {
         upper = arr[i];
         break;
      }
   }

   return [lower, upper];
};
