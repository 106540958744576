import { get, KEYS } from '../../utils/localStorage';

export const fakeLoginApi = (username: string, password: string) => {
   return new Promise((resolve, reject) => {
      setTimeout(() => {
         if (username === 'user' && password === 'password') {
            resolve({ user: { username }, token: 'valid-token' });
         } else if (username === 'admin' && password === 'password') {
            resolve({ user: { username }, token: 'valid-token-admin' });
         } else {
            reject(new Error('Invalid credentials'));
         }
      }, 1000);
   });
};

export const fakeGetUserApi = (token: string) => {
   return new Promise((resolve, reject) => {
      setTimeout(() => {
         if (token === 'valid-token') {
            resolve({ username: 'user' });
         } else {
            reject(new Error('Invalid token'));
         }
      }, 1000);
   });
};

export const fakeGetProfileApi = () => {
   return new Promise((resolve, reject) => {
      setTimeout(() => {
         switch (get(KEYS.TOKEN)) {
            case 'valid-token':
               resolve({
                  data: {
                     id: '1',
                     username: 'user',
                     roles: [
                        {
                           name: 'user',
                        },
                     ],
                  },
                  statusCode: 200,
               });
               break;
            case 'valid-token-admin':
               resolve({
                  data: {
                     id: '1',
                     username: 'admin',
                     roles: [
                        {
                           name: 'owner',
                        },
                     ],
                  },
                  statusCode: 200,
               });
               break;

            default:
               reject(new Error('Invalid token'));

               break;
         }
      }, 1000);
   });
};

export const fakeProjectByIdApi = (id: number) => {
   return new Promise((resolve, reject) => {
      setTimeout(() => {
         if (id) {
            resolve({
               name: 'COW',
               id,
               chainId: 8453,
               pairAddress: '0x3a2E1aa0e67a1d32BEe33F8322F010FEdE37A3d1',
            });
         } else {
            reject(new Error('Invalid Project'));
         }
      }, 1000);
   });
};
export const fakeProjectApi = (id: number) => {
   return new Promise((resolve, reject) => {
      setTimeout(() => {
         if (id) {
            resolve({
               data: {
                  items: [
                     {
                        createdBy: '1',
                        updatedBy: '1',
                        deletedBy: null,
                        createdAt: '2024-08-12T07:08:14.292Z',
                        updatedAt: '2024-08-12T07:08:14.292Z',
                        deletedAt: null,
                        id: '1',
                        name: 'COW',
                        tokenAddress:
                           '0x3f542a196efce4434abbaa8426f36ec3073ae585',
                        tokenSymbol: 'COW',
                        tokenName: 'COW',
                        tokenDecimal: 18,
                        tokenTotalSupply: '269717927367913.720000000000000000',
                        status: 'initializing',
                        chainId: 8453,
                        creatorId: '1',
                        deployerAddress:
                           '0xa121532e7ab664a42d21dfb56fb4a5e2544f6649',
                        operatorAddress:
                           '0x5dd673bee0d411c5da58c99cfc52d2bec76d817c',
                        tradeAddress:
                           '0x925abf99ccd33029bcb9243583f237eab15f3825',
                        pairAddress:
                           '0x3a2E1aa0e67a1d32BEe33F8322F010FEdE37A3d1',
                     },
                  ],
                  meta: {
                     total: 1,
                     current_page: 1,
                     total_pages: 1,
                     per_page: 10,
                  },
               },
               statusCode: 200,
            });
         } else {
            reject(new Error('Invalid Project'));
         }
      }, 1000);
   });
};
