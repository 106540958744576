function supportsLocalStorage() {
  return typeof Storage !== 'undefined'
}

export const set = (key, value) => {
  if (supportsLocalStorage()) {
    localStorage.setItem(key, JSON.stringify(value))
  }
}

export const get = (key) => {
  if (supportsLocalStorage()) {
    try {
      const value = JSON.parse(localStorage.getItem(key))
      return value
    } catch (e) {
      localStorage.setItem(key, null)
      return null
    }
  }
  return undefined
}

export const clearAll = () => window.localStorage.clear()

export const remove = (key) => window.localStorage.removeItem(key)

export const KEYS = {
  TOKEN: 'TOKEN',
  MY_ROLES: 'MY_ROLES',
  USER: 'USER',
}

export default {
  set,
  get,
}
