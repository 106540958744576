export const TEMPLATES = {
   t1: {
      fourHourPatterns: [
         [0.5, 0.764, 1.1, 1],
         [0.5, 0.382, 0.764, 1],
         [0.5, 0.236, 0.618, 1],
         
         // [1.236, 1.1, 0.8, 1],
         // [0.5, 0.618, 0.236, 1],
         // [0.5, 0.618, 0.382, 1],
      ],
      oneHourPatterns: [
         // Reversal Patterns
         [0.5, 0.764, 1.236, 1],
         // [1.236, 0.472, 1.236, 1],
         // [1.236, 0.618, 1.309, 1],

         // Continuation Patterns
         [0.382, 0.236, 0.618, 1],
         [0.5, 0.382, 0.764, 1],
         // [0.500, 0.236, 0.618, 1],
         // [0.500, 0.618, 0.236, 1],
         // [0.500, 0.618, 0.382, 1],
      ],
      fifteenMinutePatterns: [
         // Continuation Patterns
         [0.5, 0.618, 1],
         [0.5, 0.382, 1],
         [0.618, 0.382, 1],
         [0.382, 0.764, 1],

         // Reversal Patterns
         [1.236, 0.764, 1],
         [1.236, 0.618, 1],
      ],
      fiveMinutePatterns: [
         [0.618, 0.382, 0.764, 0.5, 1],
         [0.764, 0.236, 0.618, 0.382, 1],
         [0.236, 0.382, 0.618, 0.764, 1],
         [0.246, 0.42, 0.518, 0.864, 1],
      ],
      oneMinutePatterns: [
         // Reversal Patterns
         [1.236, 0.5, 1.618, 0.7, 1.1, 1],
         [1.206, 0.608, 1.026, 0.618, 1.316, 1],
         [1, 0.809, 1.136, 0.764, 1.209, 1],

         // Continuation Patterns
         [0.236, 0.382, 0.4, 0.5, 0.618, 1],
         [0.618, 0.236, 0.5, 0.382, 0.764, 1],
      ],
   },
   t2: {
      fourHourPatterns: [
         [0.236, 0.382, 0.618, 1],
         [0.382, 0.5, 0.764, 1],
         [0.45, 0.3, 0.764, 1],
      ],
      oneHourPatterns: [
         [0.5, 0.764, 1.236, 1],
         [0.382, 0.236, 0.618, 1],
         [0.5, 0.382, 0.764, 1],
         [0.382, 0.736, 0.518, 1],
      ],
      fifteenMinutePatterns: [
         [0.5, 0.618, 1],
         [0.5, 0.382, 1],
         [0.618, 0.382, 1],
         [0.382, 0.764, 1],
      ],
      fiveMinutePatterns: [
         // Continuation Patterns
         [0.618, 0.382, 0.764, 0.5, 1],
         [0.764, 0.236, 0.618, 0.382, 1],
         [0.236, 0.382, 0.618, 0.764, 1],
         [0.246, 0.42, 0.518, 0.864, 1],

         // Reversal Patterns
         // [1.236, 0.5, 0.7, 1.1, 1],
         // [1.206, 0.608, 1.026, 0.618, 1],
         // [1, 0.809, 1.136, 0.764, 1],
      ],
      oneMinutePatterns: [
         // Continuation Patterns
         [0.236, 0.382, 0.4, 0.5, 0.618, 1],
         [0.236, 0.4, 0.5, 0.618, 0.85, 1],
         [0.618, 0.236, 0.5, 0.382, 0.764, 1],

         // Reversal Patterns
         [1.236, 0.5, 1.618, 0.7, 1.1, 1],
         [1.206, 0.608, 1.026, 0.618, 1.316, 1],
         [1, 0.809, 1.136, 0.764, 1.209, 1],
      ],
   },
   t3: {
      fourHourPatterns: [
         [1.236, 1.382, 0.618, 1],
         [0.382, 0.5, 0.764, 1],
         [0.45, 1.3, 0.764, 1],
         [0.5, 1.764, 1.1, 1],
         [1.236, 1.1, 0.8, 1],
      ],
      oneHourPatterns: [
         [0.382, 0.236, 0.618, 1],
         [0.5, 0.382, 0.764, 1],
         [0.382, 0.736, 0.518, 1],
      ],
      fifteenMinutePatterns: [
         [0.5, 0.382, 1],
         [0.382, 0.764, 1],
         [0.3, 0.6, 1],
         [0.4, 0.7, 1],
      ],
      fiveMinutePatterns: [
         [0.618, 0.382, 0.764, 0.5, 1],
         [0.764, 0.236, 0.618, 0.382, 1],
         [0.236, 0.382, 0.618, 0.764, 1],
         [0.246, 0.42, 0.518, 0.864, 1],
         [1, 0.809, 1.136, 0.764, 1],
      ],
      oneMinutePatterns: [
         [1, 0.809, 1.136, 0.764, 1.209, 1],
         [0.236, 0.382, 0.4, 0.5, 0.618, 1],
         [0.236, 0.4, 0.5, 0.618, 0.85, 1],
         [0.618, 0.236, 0.5, 0.382, 0.764, 1],
      ],
   },
};

export const defaultTemplate = 't3';
