import { useSelector } from 'react-redux';
import { User } from '../redux/auth/type';
import { RootState } from '../redux/store';
import { useMemo } from 'react';

const usePermissions = () => {
   const user: User | null = useSelector((state: RootState) => state.auth.user);
   const rolesPermissions = useSelector(
      (state: RootState) => state.permissions.rolesPermissions,
   );

   const hasPermission = useMemo(() => {
      return (permission: string): boolean => {
         if (!user) return false;
         for (const role of user.roles) {
            const rolePermissions = rolesPermissions[role.name];
            if (
               rolePermissions &&
               Object.values(rolePermissions).flat().includes(permission)
            ) {
               return true;
            }
         }
         return false;
      };
   }, [user, rolesPermissions]);

   return { hasPermission };
};

export default usePermissions;
