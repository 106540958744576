import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from '../../../routes/PrivateRoute';
import { Skeleton } from 'antd';
import usePermissions from '../../../hooks/usePermissions';
import {
   BoostPermissions,
   ClosePermissions,
   LaunchPermissions,
   MonitorPermissions,
   ProjectPermissions,
} from '../../../constants/permissions';

const ProjectCreateComponent = lazy(() => import('../ProjectCreateComponent'));

const ProjectOverviewComponent = lazy(
   () => import('../ProjectOverviewComponent'),
);

const ProjectLaunchComponent = lazy(() => import('../ProjectLaunchComponent'));

const ProjectBoostComponent = lazy(() => import('../ProjectBoostComponent'));

const ProjectMonitorComponent = lazy(
   () => import('../ProjectMonitorComponent'),
);

const ProjectTakeProfitsComponent = lazy(
   () => import('../ProjectTakeProfitsComponent'),
);

const ProjectsListComponent = lazy(() => import('../ProjectsListComponent'));

const ProjectsRouterComponent = () => {
   const { hasPermission } = usePermissions();

   return (
      <Suspense
         fallback={
            <Skeleton
               className="container py-5 mx-auto h-full"
               paragraph={{ rows: 6 }}
               active
            />
         }
      >
         <div className="container py-5 mx-auto h-full">
            <Routes>
               <Route
                  path="/"
                  element={<PrivateRoute element={<ProjectsListComponent />} />}
               />

               <Route
                  path="create"
                  element={
                     <PrivateRoute
                        hasPermission={
                           hasPermission(ProjectPermissions.CREATE) ||
                           hasPermission(ProjectPermissions.MODIFY)
                        }
                        element={<ProjectCreateComponent />}
                     />
                  }
               />

               <Route
                  path=":id/*"
                  element={
                     <PrivateRoute
                        hasPermission={
                           hasPermission(MonitorPermissions.VIEW) ||
                           hasPermission(LaunchPermissions.VIEW) ||
                           hasPermission(BoostPermissions.VIEW)
                        }
                        element={<ProjectOverviewComponent />}
                     />
                  }
               />
               <Route
                  path=":id/launch"
                  element={
                     <PrivateRoute
                        hasPermission={hasPermission(LaunchPermissions.VIEW)}
                        element={<ProjectLaunchComponent />}
                     />
                  }
               />
               <Route
                  path=":id/boost"
                  element={
                     <PrivateRoute
                        hasPermission={hasPermission(BoostPermissions.VIEW)}
                        element={<ProjectBoostComponent />}
                     />
                  }
               />
               <Route
                  path=":id/monitor"
                  element={
                     <PrivateRoute
                        hasPermission={hasPermission(MonitorPermissions.VIEW)}
                        element={<ProjectMonitorComponent />}
                     />
                  }
               />
               <Route
                  path=":id/take-profits"
                  element={
                     <PrivateRoute
                        hasPermission={hasPermission(ClosePermissions.VIEW)}
                        element={<ProjectTakeProfitsComponent />}
                     />
                  }
               />
               <Route
                  path="*"
                  element={<PrivateRoute element={<ProjectsListComponent />} />}
               />
               <Route
                  path=":id"
                  element={
                     <PrivateRoute
                        hasPermission={
                           hasPermission(MonitorPermissions.VIEW) ||
                           hasPermission(LaunchPermissions.VIEW) ||
                           hasPermission(BoostPermissions.VIEW)
                        }
                        element={<ProjectOverviewComponent />}
                     />
                  }
               />
            </Routes>
         </div>
      </Suspense>
   );
};

export default ProjectsRouterComponent;
