/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { RefObject, useEffect, useState } from 'react';
import { Avatar, Dropdown, MenuProps, message } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { Header } from 'antd/es/layout/layout';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { logout, setUser } from '../redux/auth/authSlice';
import { useNavigate } from 'react-router-dom';
// import { getProfile } from '../services/authenServices';
import { User } from '../redux/auth/type';
import { ERROR_MESSAGE } from '../constants/common';
import LoadingComponent from './LoadingComponent';
import { fakeGetProfileApi } from '../redux/auth/authAPI';

interface HeaderComponentProps {
   headerRef: RefObject<HTMLDivElement>;
}

const HeaderComponent: React.FC<HeaderComponentProps> = ({ headerRef }) => {
   const { user, isAuthenticated } = useSelector(
      (state: RootState) => state.auth,
   );
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const [fetchingUser, setFetchingUser] = useState<boolean>(false);

   const appLogout = () => {
      dispatch(logout());
      navigate('/login');
   };

   useEffect(() => {
      if (isAuthenticated && !user) {
         const fetchUser = async () => {
            try {
               setFetchingUser(true);
               const user = (await fakeGetProfileApi()) as any;
               dispatch(setUser(user?.data as User));
               setFetchingUser(false);
            } catch (error) {
               setFetchingUser(false);
               message.error(ERROR_MESSAGE);
               appLogout();
            }
         };
         fetchUser();
      }
   }, [isAuthenticated]);

   const items: MenuProps['items'] = [
      {
         key: '1',
         label: (
            <div className="h-6 w-24 text-center font-semibold">
               {user?.username || 'user'}
            </div>
         ),
      },
      {
         key: '4',
         danger: true,
         label: 'Logout',
         icon: <LogoutOutlined />,
         onClick: appLogout,
      },
   ];

   return (
      <Header className="header" ref={headerRef}>
         <div className="container flex justify-between items-center">
            <div className="logo-container">
               <img
                  className="logo-img cursor-pointer"
                  src="/images/common/logo_v2.png"
                  onClick={() => navigate('/project')}
               />
               <div className="brand">MM Tool</div>
            </div>
            {isAuthenticated && (
               <Dropdown
                  menu={{ items }}
                  placement="bottomRight"
                  className="header-dropdown"
               >
                  <Avatar size={46} icon={<UserOutlined />} />
               </Dropdown>
            )}
            <LoadingComponent fullscreen loading={fetchingUser} />
         </div>
      </Header>
   );
};

export default HeaderComponent;
