/* eslint-disable no-empty-pattern */
import { createSlice } from '@reduxjs/toolkit';
import {
   BoostPermissions,
   ClosePermissions,
   LaunchPermissions,
   LockPermissions,
   MonitorPermissions,
   ProjectPermissions,
} from '../../constants/permissions';

interface RolePermissions {
   [role: string]: {
      [section: string]: string[];
   };
}

interface PermissionState {
   rolesPermissions: RolePermissions;
}

const initialState: PermissionState = {
   rolesPermissions: {
      user: {
         project: [ProjectPermissions.VIEW],
         monitor: [MonitorPermissions.VIEW],
      },
      owner: {
         project: [
            ProjectPermissions.VIEW,
            ProjectPermissions.CREATE,
            ProjectPermissions.MODIFY,
            ProjectPermissions.GENERATOR,
         ],
         launch: [LaunchPermissions.VIEW, LaunchPermissions.ACTION],
         boost: [BoostPermissions.VIEW, BoostPermissions.ACTION],
         monitor: [MonitorPermissions.VIEW],
         lock: [LockPermissions.VIEW, LockPermissions.ACTION],
         close: [ClosePermissions.VIEW, ClosePermissions.ACTION],
      },
      moderator: {
         project: [ProjectPermissions.VIEW],
         launch: [LaunchPermissions.VIEW],
         boost: [BoostPermissions.VIEW, BoostPermissions.ACTION],
         monitor: [MonitorPermissions.VIEW],
         lock: [LockPermissions.VIEW, LockPermissions.ACTION],
         close: [ClosePermissions.VIEW, ClosePermissions.ACTION],
      },
      viewer: {
         project: [ProjectPermissions.VIEW],
         launch: [LaunchPermissions.VIEW],
         boost: [BoostPermissions.VIEW],
         monitor: [MonitorPermissions.VIEW],
         lock: [LockPermissions.VIEW],
         close: [ClosePermissions.VIEW],
      },
   },
};

const permissionSlice = createSlice({
   name: 'permissions',
   initialState,
   reducers: {},
});

export default permissionSlice.reducer;
