import React, { useState } from 'react';
import { InputNumber, InputNumberProps } from 'antd';
import numeral from 'numeral';
import { SelectCurrency } from '../pages/Projects/type/ProjectTypes';

const InputNumberComponent: React.FC<
   InputNumberProps & { allowDecimal?: boolean; currency?: SelectCurrency }
> = ({ currency = 'ETH', ...props }) => {
   const [isDisabled, setIsDisabled] = useState(false);

   const handleChange = (value: number | string | null) => {
      const numericValue =
         typeof value === 'string' ? parseFloat(value) : value;

      if (numericValue !== null && numericValue > Number.MAX_SAFE_INTEGER) {
         setIsDisabled(true);
      } else {
         setIsDisabled(false);
      }

      if (props.onChange) {
         props.onChange(value);
      }
   };

   const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (isDisabled && event.key.match(/\d/)) {
         event.preventDefault();
      }
   };

   return (
      <InputNumber
         {...props}
         className={`w-full ${props.className}`}
         controls={false}
         min={0}
         onChange={handleChange}
         onKeyPress={handleKeyPress}
         formatter={(value) => {
            if (!value) return '';
            const numericValue = parseFloat(value.toString());
            const formatString = props.allowDecimal
               ? currency === 'ETH'
                  ? '0,0.[000000]'
                  : '0,0.[00]'
               : '0,0';
            return numeral(numericValue).format(formatString);
         }}
         parser={(value) => {
            if (!value) return '';
            const parsedValue = numeral(value).value();
            return parsedValue ? parsedValue : 0;
         }}
      />
   );
};

export default InputNumberComponent;
