import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import './styles/index.scss';
import { ConfigProvider, Layout, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import {
   BrowserRouter as Router,
   Routes,
   Route,
   useNavigate,
   Navigate,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import ProjectsRouterComponent from './pages/Projects/router/ProjectsRouterComponent';
import LoginComponent from './pages/Login/LoginComponent';
import { setupInterceptors } from './axios/axiosInstance';
import ChartGenerator2Component from './pages/ChartGenerator2/ChartGenerator2Component';

message.config({
   // top: 100,
   // duration: 2,
   maxCount: 1,
   // rtl: true,
   // prefixCls: 'my-message',
});

type ThemeData = {
   borderRadius: number;
   colorPrimary: string;
   Button?: {
      colorPrimary: string;
      algorithm?: boolean;
   };
   Divider?: {
      fontSize: number;
   };
};

const antDefaultConfig: ThemeData = {
   borderRadius: 6,
   colorPrimary: '#2C88D9',
   Button: {
      colorPrimary: '#2C88D9',
      algorithm: true,
   },
   Divider: {
      fontSize: 20,
   },
};
function App() {
   // const {
   //    token: {  },
   // } = theme.useToken();
   const headerRef = useRef<HTMLDivElement>(null);
   const footerRef = useRef<HTMLDivElement>(null);
   const [layoutHeight, setLayoutHeight] = useState<number>(0);

   useEffect(() => {
      if (headerRef.current && footerRef.current) {
         const headerHeight = headerRef.current.offsetHeight;
         const footerHeight = footerRef.current.offsetHeight;
         setLayoutHeight(headerHeight + footerHeight);
      }
   }, [headerRef.current?.offsetHeight, footerRef.current?.offsetHeight]);

   const SetupInterceptors = () => {
      const navigate = useNavigate();
      setupInterceptors(store, navigate);
      return null;
   };

   return (
      <Router>
         <Layout>
            <Provider store={store}>
               <SetupInterceptors />
               <ConfigProvider
                  componentSize="large"
                  theme={{
                     token: {
                        colorPrimary: antDefaultConfig.colorPrimary,
                        borderRadius: antDefaultConfig.borderRadius,
                     },
                     components: {
                        Button: {
                           colorPrimary: antDefaultConfig.Button?.colorPrimary,
                           algorithm: antDefaultConfig.Button?.algorithm,
                        },
                        Divider: {
                           fontSize: antDefaultConfig.Divider?.fontSize,
                        },
                     },
                  }}
               >
                  <HeaderComponent headerRef={headerRef} />
                  <Content
                     className="main-content"
                     style={{
                        height: `calc(100vh - ${layoutHeight}px)`,
                        background: 'url("/images/background/hero-bg.png")',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                     }}
                  >
                     {/* <div className="container py-5 mx-auto h-full"> */}
                     <Routes>
                        <Route
                           path="/login"
                           element={
                              <PublicRoute element={<LoginComponent />} />
                           }
                        />
                        <Route
                           path="/project/*"
                           element={
                              <PrivateRoute
                                 element={<ProjectsRouterComponent />}
                              />
                           }
                        />

                        <Route
                           path="*"
                           element={<Navigate to="/project" replace />}
                        />
                        {/* <Route
                           path="/chart-generator"
                           element={
                              <PrivateRoute
                                 element={<ChartGeneratorComponent />}
                              />
                           }
                        /> */}
                        <Route
                           path="/chart-generator"
                           element={
                              <PrivateRoute
                                 element={<ChartGenerator2Component />}
                              />
                           }
                        />
                     </Routes>
                     {/* </div> */}
                  </Content>
                  <FooterComponent footerRef={footerRef} />
               </ConfigProvider>
            </Provider>
         </Layout>
      </Router>
   );
}

export default App;
