import seedrandom from 'seed-random';

const useRandomInt = () => {
   const getRandomInt = (min: number, max: number, index: string) => {
      // Create seed for range
      const rangeSeed = `${min}-${max}-${index}`;
      const rng = seedrandom(rangeSeed, { global: false });
      
      return Math.floor(rng() * (max - min + 1)) + min;
   };

   return getRandomInt;
};

export default useRandomInt;