import { Footer } from 'antd/es/layout/layout';
import React, { RefObject } from 'react';

interface FooterComponentProps {
   footerRef: RefObject<HTMLDivElement>;
}

const FooterComponent: React.FC<FooterComponentProps> = ({ footerRef }) => {
   return (
      <Footer className="footer" ref={footerRef}>
         Copyright ©{new Date().getFullYear()} AquaFox, JSC. All rights
         reserved.
      </Footer>
   );
};

export default FooterComponent;
