/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';

export const validateDateRange = (_: any, value: any) => {

   if (!value || value.length !== 2) {
      return Promise.reject(new Error('Please input Start Date and End Date!'));
   }

   const [start, end] = value;

   if (moment(end.valueOf()).isSameOrBefore(start.valueOf())) {
      return Promise.reject(new Error('End date must be after Start date!'));
   }

   return Promise.resolve();
};
